import {ESHit, Description, Rating, ItemData} from './eshit';
import {formatDate} from '@angular/common';
import {InjectionToken} from '@angular/core';

export const ITEM_DATA = new InjectionToken<Item>('ITEM_DATA');

export class Item {
  private static _teaser = 'http://cv.iptc.org/newscodes/descriptionrole/teaser';
  private static _caption = 'http://cv.iptc.org/newscodes/descriptionrole/caption';
  private static _colour = 'http://cv.iptc.org/newscodes/colourindicator/colour';
  private readonly _data: ItemData;

  rowNr: number;
  published: string;
  item_type: string;
  guid: string;
  version: number;
  copyright_holder: string;
  copyright_notice: string;
  item_class: string;
  embargoed: string;
  pub_status: string;
  service: string[];
  signal: string[];
  html_body: string;
  urgency: number;
  content_created: string;
  rating: Rating;
  language: string;
  genre: string[];
  headline: string;
  by: string;
  creditline: string;
  description: string;
  event_start: string;
  remote_content: string;
  video_content: string;
  reference: string[];
  accno: string;

  private static ResolveDescription(data: ItemData, item_class: string): string {
    const desc: Description[] = data.description;
    if (item_class === 'text' || data.item_type === 'packageItem') {
      for (let i = 0; i < desc.length; i++) {
        if (desc[i].role === Item._teaser) {
          return desc[i].text;
        }
      }
    }
    if (item_class === 'picture' || item_class === 'video') {
      for (let i = 0; i < desc.length; i++) {
        if (desc[i].role === Item._caption) {
          return desc[i].text;
        }
      }
    }
    return (desc.length > 0) ? desc[0].text : '';
  }

  private static ResolveRemoteContent(data: ItemData, item_class: string): string {
    let remote_content = (data.remote_content.length > 0) ? data.remote_content[0].residref : '';
    if (item_class === 'graphic') {
      remote_content = '';
      for (let i = 0; i < data.remote_content.length; i++) {
        if (data.remote_content[i].contenttype === 'image/jpeg') {
          remote_content = data.remote_content[i].residref;
          if (data.remote_content[i].colourindicator === Item._colour) {
            break;
          }
        }
      }
    }
    if (item_class === 'video') {
      remote_content = '';
      for (let i = 0; i < data.remote_content.length; i++) {
        if (data.remote_content[i].contenttype === 'image/jpeg') {
          remote_content = data.remote_content[i].residref;
          break;
        }
      }
    }
    return remote_content;
  }

  private static ResolveVideoContent(data: ItemData): string {
    let video_content = '';
    for (let i = 0; i < data.remote_content.length; i++) {
      if (data.remote_content[i].contenttype === 'video/mp4') {
        video_content = data.remote_content[i].residref;
        break;
      }
    }
    return video_content;
  }

  constructor(rowNr?: number, hit?: ESHit) {
    if (hit) {
      this._data = hit._source;

      this.rowNr = rowNr;
      this.published = formatDate(this._data.published, 'medium', 'de');
      this.item_type = this._data.item_type;
      this.guid = this._data.guid;
      this.version = this._data.version;
      this.copyright_holder = (this._data.copyright_holder) ? this._data.copyright_holder.name : '';
      this.copyright_notice = this._data.copyright_notice;
      this.item_class = (this._data.item_class) ? this._data.item_class.substr(this._data.item_class.lastIndexOf('/') + 1) : '';
      this.embargoed = (this._data.embargoed) ? formatDate(this._data.embargoed, 'medium', 'de') : '';
      this.pub_status = (this._data.pub_status) ? this._data.pub_status.substr(this._data.pub_status.lastIndexOf('/') + 1) : 'usable';
      this.service = this._data.service;
      this.signal = this._data.signal;
      this.html_body = this._data.html_body;
      this.urgency = this._data.urgency;
      this.content_created = formatDate(this._data.content_created, 'medium', 'de');
      this.rating = this._data.rating;
      this.language = this._data.language;
      this.genre = this._data.genre;
      if (this.item_class === 'video') {
        this.headline = this._data.title;
      } else {
        this.headline = (this._data.headline.length > 0) ? this._data.headline[0] : '';
      }
      this.by = this._data.by;
      this.creditline = this._data.creditline;
      this.description = Item.ResolveDescription(this._data, this.item_class);
      this.event_start = (this._data.event_start) ? formatDate(this._data.event_start, 'medium', 'de') : '';
      this.remote_content = Item.ResolveRemoteContent(this._data, this.item_class);
      this.video_content = (this.item_class === 'video') ? Item.ResolveVideoContent(this._data) : '';
      this.reference = this._data.reference;
      this.accno = this._data.accno;
    }
  }

  IsCanceled(): boolean {
    return this.pub_status.includes('canceled');
  }

  HasEmbargo(): boolean {
    if (this.embargoed) {
      const embargo = new Date(this._data.embargoed);
      return new Date() < embargo;
    } else {
      return false;
    }
  }

  HasPicture(): boolean {
    return this.item_class === 'picture' || this.item_class === 'graphic' || this.item_class === 'video';
  }

  HasVideo(): boolean {
    return this.item_class === 'video';
  }
}
