import {Component, Input, OnChanges} from '@angular/core';
import {Item} from '../item';
import {CrGetItemService, BinaryRendition} from '../service/cr-getitem.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-videoloader',
  templateUrl: './video-loader.component.html',
  styleUrls: ['./video-loader.component.css']
})
export class VideoLoaderComponent implements OnChanges {
  private static _blankPicture = 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=';
  @Input() item: Item;
  videoUrl: string;
  imageUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(VideoLoaderComponent._blankPicture);

  constructor( private _crGetItemService: CrGetItemService,
               private _domSanitizer: DomSanitizer) { }

  ngOnChanges() {
    if (this.item.HasVideo()) {
      this._crGetItemService.GetSignedUrl(this.item.video_content).subscribe((data) => {
        this.videoUrl = data;
      });
      this._crGetItemService.GetBinary(this.item.remote_content, BinaryRendition.Preview).subscribe((data) => {
        this.CreateImageFromBlob(data);
      });
    }
  }

  private CreateImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(reader.result as string);
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
