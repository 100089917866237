import {OverlayRef} from '@angular/cdk/overlay';
import {PreviewOverlayComponent} from './preview-overlay/preview-overlay.component';
import {Item} from './item';

export class PreviewOverlayRef {

  componentInstance: PreviewOverlayComponent;

  constructor(private _overlayRef: OverlayRef) { }

  Close(): void {
    this._overlayRef.dispose();
    this.componentInstance = null;
  }

  Update(data: Item) {
    if (this.componentInstance) {
      this.componentInstance.item = data;
      this.componentInstance.SetEscCather();
    }
  }

  IsInitialized(): boolean {
    return (this.componentInstance) ? true : false;
  }
}
