import {formatDate} from '@angular/common';

export class SearchParameter {
  private static _ninatPrefix = 'http://cv.iptc.org/newscodes/ninature/';
  static typeSelection = [
    {key: '', value: '-'},
    {key: 'newsItem', value: 'Medienobjekt'},
    {key: 'packageItem', value: 'Paket'},
    {key: 'planningItem', value: 'Planung'},
    {key: 'conceptItem', value: 'Termin'}
  ];
  static classSelection = [
    {key: '', value: '-'},
    {key: SearchParameter._ninatPrefix.concat('text'), value: 'Text'},
    {key: SearchParameter._ninatPrefix.concat('graphic'), value: 'Grafik'},
    {key: SearchParameter._ninatPrefix.concat('picture'), value: 'Foto'},
    {key: SearchParameter._ninatPrefix.concat('data'), value: 'Daten'},
    {key: SearchParameter._ninatPrefix.concat('video'), value: 'Video'}
  ];

  private _typ: string;
  product: string;
  text: string;
  class: string;
  private _startDate: string;
  private _endDate: string;

  get typ(): string {
    return this._typ;
  }

  set typ(t: string) {
    this._typ = t;
    if (!this.IsClassAllowed()) {
      this.class = '';
    }
  }

  get startDate(): string {
    return this._startDate;
  }

  set startDate(s: string) {
    if (s) {
      this._startDate = formatDate(s, 'dd.MM.yyyy', 'de');
    } else {
      this._startDate = null;
    }
  }

  get endDate(): string {
    return this._endDate;
  }

  set endDate(s: string) {
    if (s) {
      this._endDate = formatDate(s, 'dd.MM.yyyy', 'de');
    } else {
      this._endDate = null;
    }
  }

  IsEmpty(): boolean {
    return !(this.text || this.product || this._typ || this.class || this._startDate || this._endDate);
  }

  IsClassAllowed(): boolean {
    return !this._typ || this._typ === 'newsItem';
  }
}
