import {Component} from '@angular/core';
import {environment} from '../environments/environment';
import {CognitoService} from './service/cognito.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title: string;
  version: string;

  constructor(private _cognito: CognitoService,
              private _router: Router) {
    this.title = environment.titleSuffix ? 'Content-Repository Suche - ' + environment.titleSuffix : 'Content-Repository Suche';
    this.version = '2.4.2';
  }

  public IsAuthenticated(): boolean {
    return this._cognito.IsAuthenticated();
  }

  public Logout() {
    this._cognito.TryLogout();
    this._router.navigateByUrl('/');
  }
}
