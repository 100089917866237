import {Component, OnInit, Inject} from '@angular/core';
import {environment} from '../../environments/environment';
import {CognitoService} from '../service/cognito.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

export interface ConfirmationData {
    title: string;
    label: string;
    name: string;
    newPassword: string;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    login: string;
    password: string;
    private _dialogMutex: boolean;
    private _confirmationData: ConfirmationData;

    constructor(private _cognito: CognitoService,
                private _router: Router,
                private _snackBar: MatSnackBar,
                private _dialog: MatDialog) {
        this._cognito.clientId = environment.clientId;
        this._cognito.userPoolId = environment.userPoolId;
        this._dialogMutex = false;
    }

    ngOnInit() {
    }

    CanLogin(): boolean {
        return (!!this.login && !!this.password);
    }

    UserEntered(): boolean {
        return !!this.login;
    }

    onKey(): void {
        if (this.CanLogin()) {
            this.TryLogin();
        }
    }

    openFirstPasswordDialog(): void {
        if (this._dialogMutex) {
            return;
        } else {
            this._dialogMutex = true;
        }
        const dialogRef = this._dialog.open(ConfirmationDialog, {
            width: '400px',
            data: {
                title: 'Bitte Kennwort ändern',
                label: 'Name des Nutzers'
            }
        });

        dialogRef.afterClosed().subscribe((result) => {
            this._dialogMutex = false;
            this._confirmationData = result;
            if (this._confirmationData != null) {
                this._cognito.TryChangePassword(this._confirmationData.name, this._confirmationData.newPassword, this.LoginResult);
            }
        });
    }

    openConfirmPasswordDialog(): void {
        if (this._dialogMutex) {
            return;
        } else {
            this._dialogMutex = true;
        }
        const dialogRef = this._dialog.open(ConfirmationDialog, {
            width: '400px',
            data: {
                title: 'Bitte neues Kennwort verifizieren',
                label: 'Verifizierungscode'
            }
        });

        dialogRef.afterClosed().subscribe((result) => {
            this._dialogMutex = false;
            this._confirmationData = result;
            if (this._confirmationData != null) {
                this._cognito.TryConfirmPassword(this._confirmationData.name, this._confirmationData.newPassword, this.LoginResult);
            }
        });
    }

    private LoginResult = (err: Error) => {
        if (this._cognito.IsAuthenticated()) {
            this._router.navigateByUrl('/search');
            return;
        }
        if (this._cognito.PasswordChangeNeeded()) {
            this.openFirstPasswordDialog();
            return;
        }
        if (this._cognito.VerificationNeeded()) {
            this.openConfirmPasswordDialog();
        } else {
            this._snackBar.open(err.message, '', {
                duration: 5000,
                verticalPosition: 'top',
                horizontalPosition: 'center'
                });
            this.password = '';
        }
    }

    TryLogin(): void {
        this._cognito.TryLogin(this.login, this.password, this.LoginResult);
    }

    PasswordForgotten(): void {
        this._cognito.TryPasswordForgotten(this.login, this.LoginResult);
    }
}

@Component({
    templateUrl: './login.confirmation-dialog.html',
    styleUrls: ['./login.confirmation-dialog.css']
})
export class ConfirmationDialog {
    password2: string;

    constructor(public dialogRef: MatDialogRef<ConfirmationDialog>,
                @Inject(MAT_DIALOG_DATA) public confirmationData: ConfirmationData) {
        this.confirmationData['name'] = '';
        this.confirmationData['newPassword'] = '';
        }

    onKey(): void {
        if (this.CanChange()) {
            this.CloseClick(this.confirmationData);
        }
    }

    CanChange(): boolean {
        return (!!this.confirmationData.name && !!this.confirmationData.newPassword && !!this.password2
            && this.confirmationData.newPassword === this.password2);
    }

    CloseClick(data): void {
        this.dialogRef.close(data);
    }
}
