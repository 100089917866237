import {Injectable} from '@angular/core';
import {AuthenticationDetails, CognitoUserPool, CognitoUser, CognitoAccessToken,
  CognitoIdToken, CognitoUserAttribute} from 'amazon-cognito-identity-js';

type ICallback = (error: Error) => void;

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  private _userPoolId: string;
  private _clientId: string;
  private _isAuthenticated: boolean;
  private _forcedPasswordChange: boolean;
  private _verificationNeeded: boolean;
  private _cognitoUser: CognitoUser;
  private _accToken: CognitoAccessToken;
  private _idToken: CognitoIdToken;
  private _userAttributes: CognitoUserAttribute;

  constructor() {
    this._isAuthenticated = false;
    this._forcedPasswordChange = false;
    this._verificationNeeded = false;
  }

  set userPoolId(p: string) {
    if (p) {
      this._userPoolId = p;
    }
  }

  set clientId(c: string) {
    if (c) {
      this._clientId = c;
    }
  }

  get accessToken(): string {
    if (this._accToken) {
      return this._accToken.getJwtToken();
    }
    return null;
  }

  get idToken(): string {
    if (this._idToken) {
      return this._idToken.getJwtToken();
    }
    return null;
  }

  IsAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  PasswordChangeNeeded(): boolean {
    return this._forcedPasswordChange;
  }

  VerificationNeeded(): boolean {
      return this._verificationNeeded;
  }

  TryLogin(user: string, password: string, cb: ICallback): void {
    this._isAuthenticated = false;
    this._forcedPasswordChange = false;
    this._verificationNeeded = false;
    this._accToken = null;
    this._idToken = null;
    const authenticationData = {
      Username: user,
      Password: password
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const poolData = {
      UserPoolId: this._userPoolId,
      ClientId: this._clientId
    };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: user,
      Pool: userPool
    };
    this._cognitoUser = new CognitoUser(userData);

    this._cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result: any) => {
        this._isAuthenticated = true;
        this._accToken = result.getAccessToken();
        this._idToken = result.idToken;
        if (cb)  {
          cb(null);
        }
      },

      onFailure: (err) => {
        if (cb) {
          cb(err);
        }
      },

      newPasswordRequired: (userAttributes, requiredAttributes) => {
        this._userAttributes = userAttributes;
        this._forcedPasswordChange = true;
        if (cb) {
          cb(new Error('Kennwortaktualisierung erforderlich'));
        }
      }
    });
  }

  TryChangePassword(name: string, newPassword: string, cb: ICallback): void {
    this._forcedPasswordChange = false;
    this._userAttributes['name'] = name;
    if (this._userAttributes['email_verified']) {
        delete this._userAttributes['email_verified'];
    }
    this._cognitoUser.completeNewPasswordChallenge(newPassword, this._userAttributes, {
      onSuccess: (result) => {
        if (cb) {
          cb(new Error('Bitte erneut einloggen.'));
        }
      },

      onFailure: (err) => {
        if (cb) {
          cb(err);
        }
      }
    });
  }

  TryPasswordForgotten(user: string, cb: ICallback): void {
      this._isAuthenticated = false;
      this._forcedPasswordChange = false;
      this._verificationNeeded = false;
      this._accToken = null;
      this._idToken = null;
      const poolData = {
          UserPoolId: this._userPoolId,
          ClientId: this._clientId
      };
      const userPool = new CognitoUserPool(poolData);
      const userData = {
          Username: user,
          Pool: userPool
      };
      this._cognitoUser = new CognitoUser(userData);

      this._cognitoUser.forgotPassword({
          onSuccess: (result) => {
              cb(new Error('Passwort wurde geändert.'));
          },

          onFailure: (err) => {
              cb(err);
          },

          inputVerificationCode: () => {
              this._verificationNeeded = true;
              if (cb) {
                  cb(new Error('Kennwortverifizierung erforderlich'));
              }
          }
      });
  }

  TryConfirmPassword(verificationCode: string, newPassword: string, cb: ICallback) {
      this._verificationNeeded = false;
      this._cognitoUser.confirmPassword(verificationCode, newPassword, {
          onSuccess:  () => {
              cb(new Error('Passwort wurde geändert.'));
          },
          onFailure: (err) => {
              cb(err);
          }
      });
  }

  TryLogout(): void {
    if (this._cognitoUser) {
      this._cognitoUser.signOut();
      this._isAuthenticated = false;
      this._forcedPasswordChange = false;
      this._verificationNeeded = false;
      this._accToken = null;
      this._idToken = null;
      this._cognitoUser = null;
    }
  }
}
