import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CognitoService} from './cognito.service';
import {Observable} from 'rxjs';

export enum BinaryRendition {
  Thumb,
  Preview,
  Hires
}

@Injectable({
  providedIn: 'root'
})
export class CrGetItemService {
  private static _crGetG2ItemUrl = environment.crGetG2ItemUrl;
  private static _crGetBinaryItemUrl = environment.crGetBinaryItemUrl;
  private static _crGetSignedItemUrl = environment.crGetSignedItemUrl;

  constructor(private _http: HttpClient,
              private _cognito: CognitoService) { }

  private static GetBinaryItemRenditionUrl( rendition?: BinaryRendition): string {
      switch (rendition) {
          case BinaryRendition.Thumb:
              return CrGetItemService._crGetBinaryItemUrl.concat('thumb/');
          case BinaryRendition.Preview:
              return CrGetItemService._crGetBinaryItemUrl.concat('layout/');
          default:
              return CrGetItemService._crGetBinaryItemUrl;
      }
  }

  GetG2(guid: string): Observable < Blob > {
    const httpOptions = {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + this._cognito.accessToken
          }),
          responseType: 'blob' as 'json'
      };
    return this._http.get<Blob>(CrGetItemService._crGetG2ItemUrl +  encodeURIComponent(guid), httpOptions);
  }

  GetBinary(guid: string, rendition?: BinaryRendition): Observable < Blob > {
    const httpOptions = {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + this._cognito.accessToken,
              'Accept': 'image/jpeg'
          }),
          responseType: 'blob' as 'json'
      };
    return this._http.get<Blob>(CrGetItemService.GetBinaryItemRenditionUrl(rendition) + encodeURIComponent(guid), httpOptions);
  }

  GetSignedUrl(guid: string): Observable < string > {
    const httpOptions = {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + this._cognito.idToken // Hier muss ein ID-Token stehen und kein Access-Token
          }),
          responseType: 'text' as 'json'
      };
    const url = CrGetItemService._crGetSignedItemUrl + '?urn=' + encodeURIComponent(guid) + '&lt=5'
    return this._http.get<string>(url, httpOptions);
  }
}
