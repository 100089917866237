import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {CrSearchService} from './service/cr-search.service';
import {ESQuery} from './esquery';
import {ESResult} from './esresult';
import {Item} from './item';
import {HttpErrorResponse} from '@angular/common/http';

export class CRDataSource implements DataSource<Item> {
  private _itemsSubject = new BehaviorSubject<Item[]>([]);
  private _loadingSubject = new BehaviorSubject<boolean>(false);
  private _totalSizeSubject = new BehaviorSubject<number>(0);
  private _errorSubject = new BehaviorSubject<string>('');

  public loading$ = this._loadingSubject.asObservable();
  public totalSize$ = this._totalSizeSubject.asObservable();
  public error$ = this._errorSubject.asObservable();

  constructor(private _crSearchService: CrSearchService) {
  }

  private ErrorHandling(err: HttpErrorResponse): Observable<Item[]> {
    let msg: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      msg = 'Clientfehler: ' + err.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      msg = `Serverfehlercode (${err.status}) Fehlermeldung: ${err.error.message}`;
    }
    this._errorSubject.next(msg);
    return of([]);
  }

  connect(collectionViewer: CollectionViewer): Observable<Item[]> {
    return this._itemsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this._itemsSubject.complete();
    this._loadingSubject.complete();
    this._totalSizeSubject.complete();
    this._errorSubject.complete();
  }

  LoadItems(esQuery: ESQuery): void {
    this._loadingSubject.next(true);
    this._crSearchService.Search(esQuery).pipe(
      catchError((e: HttpErrorResponse) => this.ErrorHandling(e)),
      finalize(() => this._loadingSubject.next(false))
    ).subscribe(result => {
      const esResult = Object.assign(new ESResult(), result);
      const items: Item[] = [];
      const list = esResult.GetResult();
      const base = esQuery.from + 1;
      for (let i = 0; i < list.length; i++) {
        const item = new Item(base + i, list[i]);
        items.push(item);
      }
      this._totalSizeSubject.next(esResult.GetHits());
      this._itemsSubject.next(items);
    });
  }
}
