// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  crSearchUrl: 'https://hcvx9x0pbh.execute-api.eu-central-1.amazonaws.com/prod/item/search',
  crGetG2ItemUrl: 'https://g2.cr.dpa-io.com/',
  crGetBinaryItemUrl: 'https://media.cr.dpa-io.com/',
  crGetSignedItemUrl: 'https://api.cr.dpa-io.com/signedlink/signedlink',
  userPoolId: 'eu-central-1_kBSl6zZeq',
  clientId: '4c92src6fv22mm9gmu68m9iec4',
  titleSuffix: ''
};

/*
  production: false,
  crSearchUrl: 'https://iyu56mlh5b.execute-api.eu-central-1.amazonaws.com/test77/item/search',
  crGetG2ItemUrl: 'https://g2.cr-test.dpa-io.com/',
  crGetBinaryItemUrl: 'https://media.cr-test.dpa-io.com/',
  crGetSignedItemUrl: 'https://29p6cnhzjd.execute-api.eu-central-1.amazonaws.com/Prod/signedlinkna',
  userPoolId: 'eu-central-1_kBSl6zZeq',
  clientId: '4c92src6fv22mm9gmu68m9iec4',
  titleSuffix: 'Test'

 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
