import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

@Component({
  selector: 'app-date-picker-de',
  templateUrl: './date-picker-de.component.html',
  styleUrls: ['./date-picker-de.component.css'],
  providers: [
      {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
      {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
      {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}]
})
export class DatePickerDEComponent implements OnInit {
  @Input() placeholder: string;
  @Input() date: string;
  @Output() dateChange = new EventEmitter<string>();

  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('de');
  }

  ngOnInit() {
  }

  dateChanged() {
      this.dateChange.emit(this.date);
  }
}
