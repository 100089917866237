import {Component, Input, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-classicon',
  templateUrl: './classicon.component.html',
  styleUrls: ['./classicon.component.css']
})
export class ClassiconComponent implements OnInit {
  @Input() itemClass: string;

  constructor(private _matIconRegistry: MatIconRegistry,
              private _domSanitizer: DomSanitizer,
  ) {
    this._matIconRegistry.addSvgIcon('photo', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-dpa-camera-xs.svg'));
    this._matIconRegistry.addSvgIcon('text', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-dpa-text-xs.svg'));
    this._matIconRegistry.addSvgIcon('data', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-dpa-calendar.svg'));
    this._matIconRegistry.addSvgIcon('graphic', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-dpa-stats-lg.svg'));
    this._matIconRegistry.addSvgIcon('event',
        this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-dpa-po-type-date-lg.svg'));
    this._matIconRegistry.addSvgIcon('planning',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-dpa-multiselection-lg.svg'));
    this._matIconRegistry.addSvgIcon('package', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-dpa-smartgroup.svg'));
  }

  GetIcon(): string {
    switch (this.itemClass) {
      case 'text':
        return 'text';
      case 'picture':
        return 'photo';
      case 'concept':
        return 'event';
      case 'newscoverage':
        return 'planning';
      case 'composite':
        return 'package';
      case 'data':
        return 'data';
      case 'graphic':
        return 'graphic';
      default:
        return 'text';
    }
  }

  ngOnInit() { }
}
