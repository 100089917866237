import {ComponentRef, Injectable, Injector} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {PreviewOverlayComponent} from '../preview-overlay/preview-overlay.component';
import {PreviewOverlayRef} from '../previewoverlayref';
import {Item, ITEM_DATA} from '../item';

interface PreviewOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  height?: string;
  width?: string;
  data?: Item;
}

const DEFAULT_CONFIG: PreviewOverlayConfig = {
  hasBackdrop: false,
  data: null
};

@Injectable({
  providedIn: 'root'
})
export class PreviewOverlayService {

  // Inject overlay service
  constructor(private _injector: Injector,
    private _overlay: Overlay) { }

  Open(config: PreviewOverlayConfig = {}) {
    // Returns an OverlayRef (which is a PortalHost)
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    const overlayRef = this.CreateOverlay(dialogConfig);
    const dialogRef = new PreviewOverlayRef(overlayRef);
    dialogRef.componentInstance = this.AttachDialogContainer(overlayRef, dialogConfig, dialogRef);

    return dialogRef;
  }

  private CreateOverlay(config: PreviewOverlayConfig) {
    // Returns an OverlayConfig
    const overlayConfig = this.GetOverlayConfig(config);

    // Returns an OverlayRef
    return this._overlay.create(overlayConfig);
  }

  private GetOverlayConfig(config: PreviewOverlayConfig): OverlayConfig {
    const positionStrategy = this._overlay.position()
      .global()
      .bottom()
      .right();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      positionStrategy
    });
    return overlayConfig;
  }

  private CreateInjector(config: PreviewOverlayConfig, dialogRef: PreviewOverlayRef): PortalInjector {
    // Instantiate new WeakMap for our custom injection tokens
    const injectionTokens = new WeakMap();

    // Set custom injection tokens
    injectionTokens.set(PreviewOverlayRef, dialogRef);
    injectionTokens.set(ITEM_DATA, config.data);

    // Instantiate new PortalInjector
    return new PortalInjector(this._injector, injectionTokens);
  }

  private AttachDialogContainer(overlayRef: OverlayRef, config: PreviewOverlayConfig, dialogRef: PreviewOverlayRef) {
    const injector = this.CreateInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(PreviewOverlayComponent, null, injector);
    const containerRef: ComponentRef<PreviewOverlayComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }
}
