export class CopyrightHolder {
  literal: string;
  name: string;
}

export class Rating {
  value: string;
  scale_min: string;
  scale_max: string;
  scale_unit: string;
}

export class Description {
  role: string;
  text: string;
}

export class RemoteContent {
  contenttype: string;
  layoutorientation: string;
  colourindicator: string;
  residref: string;
}

export class ItemData {
  published: string = null;
  item_type: string = null;
  guid: string = null;
  version: number = null;
  copyright_holder: CopyrightHolder = null;
  copyright_notice: string = null;
  item_class: string = null;
  embargoed: string = null;
  pub_status: string = null;
  service: string[] = null;
  signal: string[] = null;
  html_body: string = null;
  urgency: number = null;
  content_created: string = null;
  rating: Rating = null;
  language: string = null;
  genre: string[] = null;
  headline: string[] = null;
  by: string = null;
  creditline: string = null;
  description: Description[] = null;
  event_start: string = null;
  remote_content: RemoteContent[] = null;
  reference: string[] = null;
  accno: string = null;
  title: string = null;
}

export class ESHit {
  _id: string;
  _score: number;
  _source: ItemData;
}
