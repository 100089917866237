import {ESHit} from './eshit';

export class ESResult {
  took: number;
  timed_out: boolean;
  hits: {
    total: number;
    max_score: number;
    hits: ESHit[];
  };

  IsTimedOut(): boolean {
    return this.timed_out;
  }

  GetHits(): number {
    return (this.hits) ? this.hits.total : 0;
  }

  GetResultLength(): number {
    return (this.hits.hits) ? this.hits.hits.length : 0;
  }

  GetResult(): ESHit[] {
    return (this.hits.hits) ? this.hits.hits : [];
  }
}
