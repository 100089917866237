import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SearchComponent} from './search/search.component';
import {LoginComponent} from './login/login.component';
import {RoutingGuard} from './routingguard';

const routes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'search', component: SearchComponent, canActivate: [RoutingGuard]},
    {path: '', redirectTo: '/login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
