import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ESQuery} from '../esquery';
import {environment} from '../../environments/environment';
import {CognitoService} from './cognito.service';

@Injectable({
  providedIn: 'root'
})
export class CrSearchService {
  private static _crSearchUrl = environment.crSearchUrl;

  constructor(private _http: HttpClient,
              private _cognito: CognitoService) {
  }

  Search(query: ESQuery) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + this._cognito.accessToken
          })
      };
    return this._http.post(CrSearchService._crSearchUrl, query.query, httpOptions);
  }
}
