import {SearchParameter} from './searchparameter';
import {ItemData} from './eshit';

export class ESQuery {
    private static _fieldList: string[] = [];
    private static _servicePrefix = 'http://g2.dpa.com/cv/service/';
    private static _fulltextFieldList: string[] = [
        'title',
        'headline',
        'description.text',
        'body',
        'subject.name',
        'keyword',
        'by',
        'accno',
        'info_source.literal',
        'located.area_name',
        'located.continent_name',
        'located.country_name',
        'located.locality_name',
        'located.name'];
    private _queryObj;

    constructor(search?: SearchParameter) {
        if (ESQuery._fieldList.length === 0) {
            ESQuery.CreateFieldList();
        }
        this.ResetQuery();
        if (search) {
            if (search.text) {
                this.text = search.text;
            }
            if (search.product) {
                this.product = search.product;
            }
            if (search.class) {
                this.class = search.class;
            }
            if (search.typ) {
                this.typ = search.typ;
            }
            this.SetDateRange(search.startDate, search.endDate);
        }
    }

    private static CreateFieldList(): void {
        Object.keys(new ItemData()).forEach(key => {
            ESQuery._fieldList.push(key.toString());
        });
    }

    ResetQuery(): void {
        this._queryObj = {
            query: {
                bool: {
                    filter: []
                }
            },
            sort: {published: {order: 'desc'}},
            _source: ESQuery._fieldList,
            from: 0,
            size: 100
        };
    }

    get from(): number {
        return this._queryObj.from;
    }

    set from(f: number) {
        if (f || f === 0) {
            this._queryObj.from = f;
        }
    }

    set size(s: number) {
        if (s) {
            this._queryObj.size = s;
        }
    }

    set text(t: string) {
        if (t && t.length > 0) {
            // Special handling for guid like text
            if (t.toLowerCase().startsWith('urn:newsml:')) {
                const qPart = {
                    term: {
                        guid: t.toLowerCase()
                    }
                };
                this._queryObj.query.bool.filter.push(qPart);
            } else {
                const qPart = {
                    query_string: {
                        fields: ESQuery._fulltextFieldList,
                        analyze_wildcard: true,
                        default_operator: 'AND',
                        type: 'cross_fields',
                        query: t
                    }
                };
                this._queryObj.query.bool['must'] = qPart;
            }
        }
    }

    set product(p: string) {
        if (p && p.length > 0) {
            const qPart = {
                term: {
                    service: ESQuery._servicePrefix.concat(p.toLowerCase())
                }
            };
            this._queryObj.query.bool.filter.push(qPart);
        }
    }

    set class(c: string) {
        if (c && c.length > 0) {
            const qPart = {
                term: {
                    item_class: c
                }
            };
            this._queryObj.query.bool.filter.push(qPart);
        }
    }

    set typ(t: string) {
        if (t && t.length > 0) {
            const qPart = {
                term: {
                    item_type: t
                }
            };
            this._queryObj.query.bool.filter.push(qPart);
        }
    }

    SetDateRange(s: string, e: string) {
        if (s || e) {
            const qPart = {
                range: {
                    published: {
                        format: 'dd.MM.yyyy',
                        time_zone: '+01:00'
                    }
                }
            };
            if (s) {
                qPart.range.published['gte'] = s;
            }
            if (e) {
                qPart.range.published['lte'] = e;
            }
            this._queryObj.query.bool.filter.push(qPart);
        }
    }

    get query(): string {
        return JSON.stringify(this._queryObj);
    }
}
