import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {CognitoService} from './service/cognito.service';

@Injectable({
    providedIn: 'root'
})
export class RoutingGuard implements CanActivate {

    constructor(private _cognito: CognitoService,
                private _router: Router) {}

    canActivate() {
        const ok = this._cognito.IsAuthenticated();
        if (!ok) {
            this._router.navigateByUrl('/');
        }
        return ok;
    }
}
