import {Component, Inject, OnInit} from '@angular/core';
import {PreviewOverlayRef} from '../previewoverlayref';
import {ITEM_DATA} from '../item';
import {BinaryRendition, CrGetItemService} from '../service/cr-getitem.service';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-preview-overlay',
  templateUrl: './preview-overlay.component.html',
  styleUrls: ['./preview-overlay.component.css']
})
export class PreviewOverlayComponent implements OnInit {
  private static _clipIcon = 'assets/icons/icon-dpa-clipboard-lg.svg';
  private static _closeIcon = 'assets/icons/icon-dpa-close-lg.svg';
  private static _saveG2Icon = 'assets/icons/icon-dpa-save-lg.svg';
  private static _saveJpegIcon = 'assets/icons/icon-dpa-attachment-lg.svg';

  rendition = BinaryRendition.Preview;

  constructor(private _dialogRef: PreviewOverlayRef,
              @Inject(ITEM_DATA) public item: any,
              private _crGetItemService: CrGetItemService,
              private _domSanitizer: DomSanitizer,
              private _matIconRegistry: MatIconRegistry
  ) {
    this._matIconRegistry.addSvgIcon('clip', this._domSanitizer.bypassSecurityTrustResourceUrl(PreviewOverlayComponent._clipIcon));
    this._matIconRegistry.addSvgIcon('close', this._domSanitizer.bypassSecurityTrustResourceUrl(PreviewOverlayComponent._closeIcon));
    this._matIconRegistry.addSvgIcon('saveG2', this._domSanitizer.bypassSecurityTrustResourceUrl(PreviewOverlayComponent._saveG2Icon));
    this._matIconRegistry.addSvgIcon('saveJpeg', this._domSanitizer.bypassSecurityTrustResourceUrl(PreviewOverlayComponent._saveJpegIcon));
  }

  ngOnInit() {
    this.SetEscCather();
  }

  onClose() {
    this._dialogRef.Close();
  }

  SetEscCather() {
    const esc = document.getElementById('escCatcher');
    esc.focus();
  }

  IsPlainText() {
    return (this.item.item_class !== 'text');
  }

  IsHTML() {
    return (this.item.item_class === 'text');
  }

  IsPicture() {
    return (this.item.HasPicture() && !this.item.HasVideo());
  }

  IsVideo() {
    return (this.item.HasVideo());
  }

  onG2Download() {
    this._crGetItemService.GetG2(this.item.guid).subscribe((data) => {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = this.item.guid + '.xml';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  onJpegDownload() {
    this._crGetItemService.GetBinary(this.item.remote_content, BinaryRendition.Hires).subscribe((data) => {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = this.item.guid + '.jpg';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  CopyToClipboard() {
    const listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.item.guid);
      e.preventDefault();
      document.removeEventListener('copy', listener);
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
  }
}
