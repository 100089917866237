import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {MatSnackBar, MatPaginator, MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {CRDataSource} from '../crdatasource';
import {SearchParameter} from '../searchparameter';
import {CrSearchService} from '../service/cr-search.service';
import {BinaryRendition} from '../service/cr-getitem.service';
import {ESQuery} from '../esquery';
import {Item} from '../item';
import {PreviewOverlayService} from '../service/previewoverlay.service';
import {PreviewOverlayRef} from '../previewoverlayref';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements AfterViewInit, OnInit {
  private static _searchIcon = 'assets/icons/icon-dpa-search.svg';
  private static _searchIconDisabled = 'assets/icons/icon-dpa-search-disabled.svg';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: CRDataSource;
  displayedColumns = ['rowNr', 'icon', 'published', 'headline', 'guid'];
  searchParameterClass = SearchParameter;
  search: SearchParameter;
  esQuery: ESQuery;
  initialPagesize = 50;
  rendition = BinaryRendition.Thumb;
  dialogRef: PreviewOverlayRef;

  constructor(private _snackBar: MatSnackBar,
              private _matIconRegistry: MatIconRegistry,
              private _domSanitizer: DomSanitizer,
              private _crSearchService: CrSearchService,
              private _previewService: PreviewOverlayService) {
    this._matIconRegistry.addSvgIcon('search',
        this._domSanitizer.bypassSecurityTrustResourceUrl(SearchComponent._searchIcon));
    this._matIconRegistry.addSvgIcon('Search-disabled',
        this._domSanitizer.bypassSecurityTrustResourceUrl(SearchComponent._searchIconDisabled));
    this.search = new SearchParameter();
  }

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Treffer pro Seite';
    this.dataSource = new CRDataSource(this._crSearchService);
    this.dataSource.error$.subscribe(msg => {
      if (msg) {
        this._snackBar.open(msg, null, {duration: 3000});
        console.error(msg);
      }
    });
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe(() => this.handlePage());
  }

  SetStartDate(d: string) {
    this.search.startDate = d;
  }

  SetEndDate(d: string) {
    this.search.endDate = d;
  }

  onKey() {
    if (!this.search.IsEmpty()) {
      this.onSearchClick(this.search);
    }
  }

  private ExecSearch(query: ESQuery): void {
    query.from = this.paginator.pageIndex * this.paginator.pageSize;
    query.size = this.paginator.pageSize;
    this.dataSource.LoadItems(query);
  }

  onSearchClick(search: SearchParameter): void {
    this.esQuery = new ESQuery(search);
    this.paginator.pageIndex = 0;
    this.ExecSearch(this.esQuery);
    if (this.dialogRef) {
      this.dialogRef.Close();
      this.dialogRef = null;
    }
  }

  private handlePage(): void {
    this.ExecSearch(this.esQuery);
  }

  private onRowClick(element: Item): void {
    if (element) {
      if (this.dialogRef && this.dialogRef.IsInitialized()) {
        this.dialogRef.Update(element);
      } else {
        this.dialogRef = this._previewService.Open({data: element});
      }
    }
  }
}
