import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe, 'de');
import {OverlayModule} from '@angular/cdk/overlay';
import {
  MatCardModule,
  MatListModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonModule,
  MatSelectModule,
  MatTableModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatPaginatorModule,
  MatIconModule,
  MatTooltipModule,
  MatToolbarModule,
  MatDialogModule,
  MatDialogRef
} from '@angular/material';
import {AppComponent} from './app.component';
import {SearchComponent} from './search/search.component';
import {ClassiconComponent} from './classicon/classicon.component';
import {PictureLoaderComponent} from './picture-loader/picture-loader.component';
import {PreviewOverlayComponent} from './preview-overlay/preview-overlay.component';
import {DatePickerDEComponent} from './date-picker-de/date-picker-de.component';
import {LoginComponent, ConfirmationDialog} from './login/login.component';
import {VideoLoaderComponent} from './video-loader/video-loader.component';

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    ClassiconComponent,
    PictureLoaderComponent,
    PreviewOverlayComponent,
    DatePickerDEComponent,
    LoginComponent,
    ConfirmationDialog,
    VideoLoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    OverlayModule,
    MatCardModule,
    MatListModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatIconModule,
    MatTooltipModule,
    MatToolbarModule,
    MatDialogModule,
  ],
  providers: [{provide: MatDialogRef, useValue: {}}],
  entryComponents: [
    PreviewOverlayComponent,
    ConfirmationDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
